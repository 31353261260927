import { createPopup }              from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import React, { FunctionComponent } from 'react'
import {
    utcTimeStringtoDateWords,
    utcTimeStringtoTimeWords,
    utcTimeStringtoTimeZone
}                                   from '../../../../../gatsby/src/utilities/helpers'
import {
    EventSelector,
    MainImage,
    TrainingEvent
}                                   from '../entities/schema'
import { SanityPreviewImage }       from './SanityPreviewImage'
import SanityLink                   from './text/Link'

interface RawChildren {
    _key: string
    _type: string
    marks: any[]
    text: string
}

interface Excerpt {
    _key: string
    _type: string
    style: string
    list?: any
    _rawChildren: RawChildren[]
}

export interface OpenLawEvent { // Called OpenLawEvent to avoid a collision with a type that is part of the DOM
    startDateTime: string;
    title: string;
    tags: string[];
    registrationUrl: string;
    platform: string;
    mainImage: MainImage;
    endDateTime: string;
    duration: number;
    publishedAt: string;
    excerpt: Excerpt[];

    body?: any
    slug: { current: string }
    // remember you can use the JSON to TS interface conversion tool.
}


interface EventListProps {
    data: EventSelector
}

const EventsList: FunctionComponent<EventListProps> = (props) => {

    const events: TrainingEvent[] = props.data.eventList

    const renderEventItem = (event: TrainingEvent) => {
        if (Date.parse(event.startDateTime) > Date.now()) {
            const {toggle} = createPopup(event.registrationId)
            return (
                <section className="flex justify-center ml-6">
                    <section className="w-full flex gap-4 mb-8 border-2 border-gray-200 shadow rounded p-6 -ml-6">
                        <div className="w-full sm:w-2/3 mr-6">
                            <div className="mb-2">
                                <h2 className="text-2xl text-black font-medium">
                                    {event.title}
                                </h2>

                                <p className="pt-4">
                                        <span className="text-xl font-light">
                                           {
                                               utcTimeStringtoTimeWords(event.startDateTime)
                                           }
                                        </span>
                                    <br/>
                                    <span>
                                           {
                                               utcTimeStringtoDateWords(event.startDateTime)
                                           }
                                        </span>
                                    <br/>
                                    <span className="font-light">
                                            {
                                                utcTimeStringtoTimeZone(event.startDateTime)
                                            }
                                        </span>
                                </p>


                            </div>
                            <div className="flex flex-row -ml-6">
                                <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded ml-6 mr-6 cursor-pointer"
                                   onClick={toggle}>
                                    Register
                                </a>
                                <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded">
                                    {
                                        event?.eventInfoPageButton?.url ? (
                                            <SanityLink node={event.eventInfoPageButton.url}
                                                        text={event.eventInfoPageButton.buttonText}/>
                                        ) : null
                                    }
                                </a>
                            </div>
                        </div>
                        <div className="w-1/3 justify-center items-center hidden sm:flex">
                            <SanityPreviewImage imageRef={event.mainImage}/>
                        </div>
                    </section>
                </section>
            )
        }
    }

    return (
        <section className="relative mx-auto max-w-7xl">
            {
                events &&
                events.length >= 1 ? (
                    <section>
                        {
                            events.map((event) => event.publishedAt ? renderEventItem(event) : null)
                        }
                    </section>
                ) : (
                    <p>
                        No events data present.
                    </p>
                )
            }
        </section>
    )
}

export default EventsList






